<template>
  <v-card flat class="fire-studio--container">
    <router-view v-if="user" panel />
    <v-dialog
      :value="!user && !authError"
      max-width="480"
      persistent
    >
      <v-card outlined>
        <v-card-title class="justify-center">
          Logging into studio
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-progress-circular indeterminate />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ProjectStudio',
  computed: {
    ...mapState('firebase', ['user', 'authError'])
  }
}
</script>

<style lang="sass">
  .fire-studio--container
    @media #{map-get($display-breakpoints, 'sm-and-up')}
      margin: -12px
      width: calc(100% + 24px)
      max-width: unset
</style>
